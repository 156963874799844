import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import StorygramGUI from './components/StorygramWrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "importing-data"
    }}>{`Importing Data`}</h1>
    <p>{`The Storygram accepts three different data structures as input.`}</p>
    <h2 {...{
      "id": "array"
    }}>{`Array`}</h2>
    <p>{`Data in the array structure consists of an array of objects, each of which contains a number or a datestring in which a event occurs and a array containing the involved actors. Datestrings have to be parseable the Dat.parse function, more information can be found `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/parse"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Below, a basic sample of this data structure is shown. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`data=[
    {event: 1, actors: ['a', 'b', 'c']},
    {event: 2, actors: ['d', 'b', 'e']},
    {event: 3, actors: ['d', 'a']}
  ]
`}</code></pre>
    <p>{`Every field of the object gets fed in the 'data' attribute of its event. This allows e.g. custom filtering.`}</p>
    <p>{`This data can be fed into the StorygramGUI component in the data props. Then, specify in the config props object that the dataFormat is of type array and the key of the eventField and the actorArray.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`config={
    dataFormat: 'array',
    eventField: 'event',
    actorArrayField: 'actors',
  }
`}</code></pre>
    <p>{`Below is an interactive example of the resulting Storygram. Note that the data and config objects have enclosing curly braces because they are embedded into a React component.`}</p>
    <Playground __position={0} __code={'<StorygramGUI\n  data={[\n    { event: 1, actors: [\'a\', \'b\', \'c\'] },\n    { event: 2, actors: [\'d\', \'b\', \'e\'] },\n    { event: 3, actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 1,
        actors: ['a', 'b', 'c']
      }, {
        event: 2,
        actors: ['d', 'b', 'e']
      }, {
        event: 3,
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors'
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "table"
    }}>{`Table`}</h2>
    <p>{`Data in the table structure consists of an array of objects, each of which contains a number or a datestring in which a event occurs and different fields containing the involved actors. Datestrings have to be parseable the Dat.parse function, more information can be found `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/parse"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Below, a basic sample of this data structure is shown. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`data=[
    {event: 1, actor1: 'a', actor2: 'b', actor3: 'c'},
    {event: 2, actor1: 'd', actor2: 'b', actor3: 'e'},
    {event: 3, actor1: 'd', actor2: 'a'}
  ]
`}</code></pre>
    <p>{`Every field of the object gets fed in the 'data' attribute of its event. This allows e.g. custom filtering.`}</p>
    <p>{`This data can be fed into the StorygramGUI component in the data props. Then, specify in the config props object that the dataFormat is of type array and the key of the eventField and the keys of the actorFields.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`config={
    dataFormat: 'table',
    eventField: 'event',
    actorFields: ['actor1', 'actor2', 'actor3'],
  }
`}</code></pre>
    <Playground __position={1} __code={'<StorygramGUI\n  data={[\n    { event: 1, actor1: \'a\', actor2: \'b\', actor3: \'c\' },\n    { event: 2, actor1: \'d\', actor2: \'b\', actor3: \'e\' },\n    { event: 3, actor1: \'d\', actor2: \'a\' },\n  ]}\n  config={{\n    dataFormat: \'table\',\n    eventField: \'event\',\n    actorFields: [\'actor1\', \'actor2\', \'actor3\'],\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 1,
        actor1: 'a',
        actor2: 'b',
        actor3: 'c'
      }, {
        event: 2,
        actor1: 'd',
        actor2: 'b',
        actor3: 'e'
      }, {
        event: 3,
        actor1: 'd',
        actor2: 'a'
      }]} config={{
        dataFormat: 'table',
        eventField: 'event',
        actorFields: ['actor1', 'actor2', 'actor3']
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "range"
    }}>{`Range`}</h2>
    <p>{`Data in the range structure consists of an array of objects, each of which contain information of an actor and two fields indicating a start and an end moment in number or datestring format. Datestrings have to be parseable the Dat.parse function, more information can be found `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/parse"
      }}>{`here`}</a>{`.`}</p>
    <p>{`An actor can have multiple ranges.
Below, a basic sample of this data structure is shown. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`data=[
    {actor: 'a', end: 1},
    {actor: 'a', start: 3},
    {actor: 'b', end: 2},
    {actor: 'c', end: 1},
    {actor: 'd', start: 2, end: 3},
    {actor: 'e', start: 2, end: 2},
  ]
`}</code></pre>
    <p>{`If the startField is null or undefined, it means that the actor is grouped from the first group on. If the endField is null or undefined, it means that the actor is grouped until the last group.`}</p>
    <p>{`Every field of the object gets fed in the 'data' attribute of its actor. This allows e.g. `}<a parentName="p" {...{
        "href": "/filtering#custom-actor-filter"
      }}>{`custom filtering`}</a>{`.`}</p>
    <p>{`This data can be fed into the StorygramGUI component in the data props. Then, specify in the config props object that the dataFormat is of type range, the key of the actorField, the fromField and the toField.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`config={
    dataFormat: 'ranges',
    actorField: 'actor',
    startField: 'start',
    endField: 'end'
  }
`}</code></pre>
    <Playground __position={2} __code={'<StorygramGUI\n  data={[\n    { actor: \'a\', end: 1 },\n    { actor: \'a\', start: 3 },\n    { actor: \'b\', end: 2 },\n    { actor: \'c\', end: 1 },\n    { actor: \'d\', start: 2, end: 3 },\n    { actor: \'e\', start: 2, end: 2 },\n  ]}\n  config={{\n    dataFormat: \'ranges\',\n    actorField: \'actor\',\n    startField: \'start\',\n    endField: \'end\',\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        actor: 'a',
        end: 1
      }, {
        actor: 'a',
        start: 3
      }, {
        actor: 'b',
        end: 2
      }, {
        actor: 'c',
        end: 1
      }, {
        actor: 'd',
        start: 2,
        end: 3
      }, {
        actor: 'e',
        start: 2,
        end: 2
      }]} config={{
        dataFormat: 'ranges',
        actorField: 'actor',
        startField: 'start',
        endField: 'end'
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "datestrings"
    }}>{`Datestrings`}</h2>
    <p>{`When working with datestrings as eventvalues, simply put them in the event field as shown below. Note: the right and bottom margin is extended to show the full date.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`data=[
    {event: '1/4/2019', actors: ['a', 'b', 'c']},
    {event: '2/4/2019', actors: ['d', 'b', 'e']},
    {event: '3/4/2019', actors: ['d', 'a']}
  ]
`}</code></pre>
    <Playground __position={3} __code={'<StorygramGUI\n  data={[\n    { event: \'1/4/2019\', actors: [\'a\', \'b\', \'c\'] },\n    { event: \'2/4/2019\', actors: [\'d\', \'b\', \'e\'] },\n    { event: \'3/4/2019\', actors: [\'d\', \'a\'] },\n  ]}\n  config={{\n    dataFormat: \'array\',\n    eventField: \'event\',\n    actorArrayField: \'actors\',\n    marginRight: 80,\n    marginBottom: 70,\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: '1/4/2019',
        actors: ['a', 'b', 'c']
      }, {
        event: '2/4/2019',
        actors: ['d', 'b', 'e']
      }, {
        event: '3/4/2019',
        actors: ['d', 'a']
      }]} config={{
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
        marginRight: 80,
        marginBottom: 70
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>
    <h2 {...{
      "id": "actor-split-callback"
    }}>{`Actor split callback`}</h2>
    <p>{`If the data is in `}<a parentName="p" {...{
        "href": "/importing_data#array"
      }}>{`array`}</a>{` or `}<a parentName="p" {...{
        "href": "/importing_data#table"
      }}>{`table`}</a>{` structure but every element still contains many actors separated for example by a comma, it is possible to further split those with a actor split callback that returns a string array. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`data=[
    {event: 0, actors1: 'a,z', actors2: 'b,y', actors3: 'c'},
    {event: 1, actors1: 'd,x', actors2: 'b', actors3: 'e,y'},
    {event: 2, actors1: 'd,k,z', actors2: 'a,y,x'}
  ]
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`actorSplitFunction?: ((arg: string) => string[]) | undefined;
`}</code></pre>
    <Playground __position={4} __code={'<StorygramGUI\n  data={[\n    { event: 0, actors1: \'a,z\', actors2: \'b,y\', actors3: \'c\' },\n    { event: 1, actors1: \'d,x\', actors2: \'b\', actors3: \'e,y\' },\n    { event: 2, actors1: \'d,k,z\', actors2: \'a,y,x\' },\n  ]}\n  config={{\n    dataFormat: \'table\',\n    eventField: \'event\',\n    actorFields: [\'actors1\', \'actors2\', \'actors3\'],\n    actorSplitFunction: input => input.split(\',\'),\n  }}\n></StorygramGUI>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StorygramGUI,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StorygramGUI data={[{
        event: 0,
        actors1: 'a,z',
        actors2: 'b,y',
        actors3: 'c'
      }, {
        event: 1,
        actors1: 'd,x',
        actors2: 'b',
        actors3: 'e,y'
      }, {
        event: 2,
        actors1: 'd,k,z',
        actors2: 'a,y,x'
      }]} config={{
        dataFormat: 'table',
        eventField: 'event',
        actorFields: ['actors1', 'actors2', 'actors3'],
        actorSplitFunction: input => input.split(',')
      }} mdxType="StorygramGUI"></StorygramGUI>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      